import logo from "../../assets/images/logo-inverted.svg";
import "./Footer.scss";

const Footer = () => (
  <footer className="footer">
    {/* <div className="footer-columns">
      <div className="footer-column">
        <img src={logo} alt="Zeesta Labs Logo" />
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <ul>
          <li>AI Whitepaper</li>
          <li>About Us</li>
          <li>Our Science</li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Social</h3>
        <ul>
          <li>LinkedIn</li>
          <li>Twitter</li>
          <li>Facebook</li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Support</h3>
        <ul>
          <li>Contact</li>
          <li>Privacy Policy</li>
          <li>Terms of Use</li>
        </ul>
      </div>
    </div> */}
    <div className="copyright">© 2024 Zeesta Labs. All Rights Reserved.</div>
  </footer>
);

export default Footer;
