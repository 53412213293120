import { PopupButton } from "@typeform/embed-react";
import heroSectionImage from "../../assets/images/hero-img-compressed.png";
import ccmSectionImage from "../../assets/images/ccm-img-compressed.png";
import userExpSectionImage from "../../assets/images/user-exp-img-compressed.png";
// import level1Image from "../../assets/images/level-1.png";
// import level2Image from "../../assets/images/level-2.png";
// import level3Image from "../../assets/images/level-3.png";
import empoweringSectionImage from "../../assets/images/empowering-img-compressed.png";
import embraceSectionImage from "../../assets/images/embrace-img-compressed.png";
import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <div className="section hero-section">
        <div className="section-text-content">
          <h1 className="section-h1">
            Better health through personalised insights
          </h1>
          <h3 className="section-h2">
            AI-powered nowcasting of health risks and markers, to improve your
            healthspan.
          </h3>
          <div className="section-ctas">
            <a
              href="https://testflight.apple.com/join/HeedG8nw"
              className="section-cta"
              target="_blank"
              rel="noreferrer"
            >
              Checkout our app
            </a>
            <PopupButton className="section-cta" id="e5FdASgO">
              Join our study
            </PopupButton>
          </div>
        </div>
        <div className="section-img">
          <img src={heroSectionImage} alt="Zeesta Labs App Preview" />
        </div>
      </div>

      <div className="section ccm-section">
        <div className="section-text-content">
          <h1 className="section-h1">
            The world's first <span className="bold">CMM*</span>
            <p className="subheading bold">* Continuous Marker Monitor</p>
          </h1>
          <h3 className="section-h2">
            Using the latest (and proprietary) medical research to put your
            wearable data into perspective.
          </h3>
          <div className="section-ctas">
            <a
              href="https://testflight.apple.com/join/HeedG8nw"
              className="section-cta"
              target="_blank"
              rel="noreferrer"
            >
              Checkout our app
            </a>
          </div>
        </div>
        <div className="section-img">
          <img src={ccmSectionImage} alt="Zeesta Labs App Preview" />
        </div>
      </div>

      <div className="section user-experience">
        <div className="section-text-content">
          <h1 className="section-h1">
            Make your experience more{" "}
            <span className="bold">personalised</span>
          </h1>
          <h3 className="section-h2">
          Incorporate additional data modalities to improve your personal AI's accuracy.
          </h3>
        </div>
        <div className="section-img">
          <img src={userExpSectionImage} alt="Zeesta Labs App Preview" />
        </div>
      </div>

      {/* <div className="section levels">
        <div className="section-text-content">
          <div className="level">
            <h3>Level 1</h3>
            <div className="level-img">
              <img src={level1Image} alt="Zeesta Labs App Preview" />
            </div>
            <h3 className="section-h2">
              Personalised risk advice using wearable only
            </h3>
          </div>
          <div className="level">
            <h3>Level 2</h3>
            <div className="level-img">
              <img src={level2Image} alt="Zeesta Labs App Preview" />
            </div>
            <h3 className="section-h2">
              Further personalise the model by training it with your own EHR*
              data (eg. blood tests)
            </h3>
          </div>
          <div className="level">
            <h3>Level 3</h3>
            <div className="level-img">
              <img src={level3Image} alt="Zeesta Labs App Preview" />
            </div>
            <h3 className="section-h2">
              Add more modalities, more regularly, to achieve most personalised
              and accurate experience
            </h3>
          </div>
        </div>
      </div> */}

      <div className="section empowering-section">
        <div className="section-text-content">
          <h1 className="section-h1">
            Empowering you in navigating your health
          </h1>
          <h3 className="section-h2">
            Visual and conversational interaction with the app, using our
            cutting-edge generative AI agents.
          </h3>
          <div className="section-ctas">
            <a
              href="https://testflight.apple.com/join/HeedG8nw"
              className="section-cta"
              target="_blank"
              rel="noreferrer"
            >
              Checkout our app
            </a>
          </div>
        </div>
        <div className="section-img">
          <img src={empoweringSectionImage} alt="Zeesta Labs App Preview" />
        </div>
      </div>

      <div className="section embrace-section">
        <div className="section-text-content">
          <h1 className="section-h1">
            Embrace a healthier life today
          </h1>
          <div className="section-ctas">
            <a
              href="https://testflight.apple.com/join/HeedG8nw"
              className="section-cta"
              target="_blank"
              rel="noreferrer"
            >
              Checkout our app
            </a>
          </div>
        </div>
        <div className="section-img">
          <img src={embraceSectionImage} alt="Zeesta Labs App Preview" />
        </div>
      </div>
    </div>
  );
};

export default Home;
